import { Link } from "gatsby-plugin-intl"
import React from "react"
import Img from "gatsby-image"

import JumboStyles from './JumboHeader.module.scss'
import AnyLink from "../../hoc/anyLink/AnyLink"
import Notification from "../../hoc/notification/notification"

const JumboHeader = ({ heading, subtitle, text, link, boxTitle, boxSubTitle, boxTrialTitle, boxListItems, image, freeSignUp }) => (
    <section className={["section-padding", JumboStyles.jumboHeader].join(' ')}>
        <div className="container">
            {/* <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-8 col-lg-6 space-xs-up">
                    <Notification />
                </div>
            </div> */}
            <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-6 col-lg-5 space-xs space-sm text-center-xs text-left-md">
                    <h1>{ heading }</h1>
                    {subtitle ? <h4> {subtitle } </h4> : null}
                    <p>{ text }</p>
                    <AnyLink className="btn btn-large space-xs space-sm" to={ link.slug }>{ link.linkTitle }</AnyLink>
                </div>
                <div className="col col-xs-12 col-md-6 col-lg-5 end-xs flex">
                    <div className={["card", JumboStyles.card].join(' ')}>
                        <h2>{ boxTitle } <span className="h6">{ boxSubTitle }</span></h2> 
                        {freeSignUp ? <h5 className="text-bold">{freeSignUp}</h5> : null}
                        <p className={!freeSignUp ? "text-bold" : null}>{boxTrialTitle}</p>
                        <ul className="list-unstyled list-checkmark">
                            { boxListItems.map((item, i) => <li key={i} className="checkmark">{ item }</li>)}
                        </ul>
                    </div>
                    {image ?
                        <Img style={{overflow: 'visible'}} className={JumboStyles.image} fadeIn={false} fluid={image} />              
                    : null}
                    
                </div>
            </div>
        </div>
    </section>
)

export default JumboHeader