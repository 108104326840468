import React from "react"
import AnyLink from "../../hoc/anyLink/AnyLink"

import ThreeUpStyles from './ThreeUp.module.scss'

const ThreeUp = ({ title, text, elements, cta }) => {
    return (
    <section className={["section-padding", ThreeUpStyles.section].join(' ')}>
        <div className="container">
            <div className="row center-xs start-xs">
                <div className="col col-xs-12 text-center space-xs-up">
                    { title ? <h2>{ title }</h2> : null }
                    { text ? <p>{ text }</p> : null }
                </div>
                {elements && elements.length > 0 && elements.map((el, i) => (
                <div key={i} className="col col-xs-12 col-md-4 col-lg-3 space-xs-up text-center">
                    <h2 className={ThreeUpStyles.circle}>{ i + 1 }</h2>
                    <h4>{ el.title }</h4>
                    <p>{ el.text }</p>
                </div>
                ))}
            </div>
            {cta && cta.length > 0 ?
            <div className="row">
                <div className="col col-xs-12 text-center">
                    {cta.map((c, i) => <AnyLink key={i} to={c.link} className="btn btn-primary btn-large">{c.linkTitle}</AnyLink>)}
                </div>
            </div>
            : null }
        </div>
    </section>
)}

export default ThreeUp
