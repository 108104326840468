import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import AnyLink from "../components/hoc/anyLink/AnyLink"

import SEO from "../components/seo"
import JumboHeader from "../components/sections/JumboHeader/JumboHeader"
import ThreeUp from "../components/sections/ThreeUp/ThreeUp"
import Notification from "../components/hoc/notification/notification"

const Pricing = () => {

    const i18n = useIntl().formatMessage

    return (
    <>
        <SEO title={i18n({ id: "pages.index.jumbo.title" })} />
            <section className="section-padding bg-lightblue">
            <div className="container">
            <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-8 col-lg-6 space-xs-up">
                    <Notification />
                </div>
            </div>
                <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-4">
                        <h1>Én samlet pris</h1>
                        <p>Ingen skjulte gebyrer eller ekstrapriser. For 269,- ex moms får du en færdig hjemmeside, som er tilpasset netop din branche, komplet med domæne, tekst- og billedforslag, sikkerhed, søgemaskineoptimering, stort billedbibliotek og meget mere.</p>
                        <p>Du kan prøve Worksiter gratis i 7 dage, hvor du få adgang til alle funktioner og en fuldt fungerende hjemmeside.</p>
                        <AnyLink to="/register" className="btn btn-primary btn-large">Prøv gratis</AnyLink>
                    </div>
                    <div className="col col-xs-12 col-md-4">
                        <div className={["card"].join(' ')}>
                            <h2>{ i18n({ id: "pages.index.jumbo.price" }) } <span className="h6">{ i18n({ id: "pages.index.jumbo.month" }) }</span></h2> 
                            <ul className="list-unstyled list-checkmark">
                                { i18n({ id: "pages.pricing.benefits" }).split(', ').map((item, i) => <li key={i} className="checkmark">{ item }</li>)}
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
            </section>
            <ThreeUp 
                title="Registrér dig og vær online på 5 minutter" 
                elements={
                    [
                    {
                        title: i18n({ id: "pages.index.three_up.texts.1.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.1.text" })
                    },
                    {
                        title: i18n({ id: "pages.index.three_up.texts.2.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.2.text" })
                    },
                    {
                        title: i18n({ id: "pages.index.three_up.texts.3.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.3.text" })
                    }
                    ]
                }
                cta={[{link: "/register", linkTitle: "Prøv det"}]}
                />
        

    </>
    )
    }

export default Pricing
